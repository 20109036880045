/* You can add global styles to this file, and also import other style files */
.block-outside-view {
  display: flex;
  position: absolute;
  top: -1000px;
  right: -1000px;
}


.loader-ct {
  margin: 0 auto;
  background: #ffffff;
  padding: 20px 25px 25px;
  height: 100%;
}

.loader-hide {
  z-index: 10;
  border: 0;
  opacity: 0;
  -webkit-animation: fade 1s linear; /* Chrome, Opera 15+, Safari 5+ */
  animation: fade 1s linear; /* Chrome, Firefox 16+, IE 10+, Opera */
  animation-fill-mode: forwards;
}

.loader {
  animation: spin 1s linear infinite;
  z-index: 5;
}

@keyframes fade {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

.loader-hide,
.loader,
.loader-img-timeline,
.loader-ring,
.loader-text {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.loader {
  display: block;
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: #16ABB7;
}
